import { useAuth, defineNuxtRouteMiddleware, navigateTo } from "#imports"
import type { UserSession } from "~/composables/currentUser"

export default defineNuxtRouteMiddleware(to => {
  const { data, signIn, status } = useAuth()

  // Return immediately if user is already authenticated
  if (status.value === "authenticated") {
    if ((data.value?.user as unknown as UserSession).role === "manager") {
      return
    } else {
      return navigateTo("/")
    }
  }
  /**
   * We cannot directly call and/or return `signIn` here as `signIn` uses async composables under the hood, leading to "nuxt instance undefined errors", see https://github.com/nuxt/framework/issues/5740#issuecomment-1229197529
   *
   * So to avoid calling it, we return it immediately.
   */
  return signIn(undefined, { callbackUrl: to.path }) as ReturnType<
    typeof navigateTo
  >
})
